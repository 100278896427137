import axios from 'axios';
import { useEffect, useState } from 'react'
import './App.css';
import './Favorites.css'
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';



function Favorites() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const [isLoaded, setisLoaded] = useState(false)

    const [token, setToken] = useState('')

    const [data, setData] = useState('')

    const [colorRefresher, setColorRefresher] = useState(false)

    function initColors() {
        const favoriteButtons = document.querySelectorAll('.favorite-container');
        favoriteButtons.forEach(async (button) => {
            if (button.dataset.product === 'productName') { setColorRefresher(!colorRefresher) }
            const isFav = await isFavorite(button.dataset.product);
            if (isFav !== 'Wrong token' && isFav !== 'No token provided') {
                button.style.color = isFav ? '#ff0077' : '#e7e7e7';
            } else {
                button.style.color = '#e7e7e7'
            }
            if (button.getAttribute('listener') === 'true') { return }
            button.addEventListener('click', async () => {
                const product = button.dataset.product;
                await favorite(product);
                const isFav = await isFavorite(product);
                if (isFav !== 'Wrong token' && isFav !== 'No token provided') {
                    button.style.color = isFav ? '#ff0077' : '#e7e7e7';
                } else {
                    button.style.color = '#e7e7e7'
                }
            });
            button.setAttribute('listener', 'true');
        });
    }


    async function favorite(product) {
        await axios.post(url.concat('/favorite'), { token: sessionStorage.getItem('token'), product: product })
        authCall()
    }

    const authCall = async () => {
        return await axios.post(url.concat('/favorites'), { token: sessionStorage.getItem('token') }).then((res) => {

            let tempData = []

            if (res.data === 'Wrong token') { return setData(<h2 style={{ color: "red" }}>Wrong token</h2>) }

            if (res.data === 'No token provided') { return setData(<h2 style={{ color: "red" }}>No token provided</h2>) }

            if (res.data === 'No favorited products') { return setData(<h2>No favorited products</h2>) }

            for (let i = 0; i < res.data.length; i++) {

                tempData.push(
                    <div className='toppers-products'>
                        <div className='topper-img-container'>
                            <div className='topper-img-blankspace'></div>
                            <div className='topper-img-container-2'><img className='topper-img' src={res.data[i].imgLink} alt='topper 1' /></div>
                            <button className='favorite-container' data-product={`${res.data[i].productName}`} onClick={() => { favorite(res.data[i].productName) }}><i class="fa-regular fa-heart"></i></button>
                        </div>
                        <div className='topper-info-container'>
                            <label className='topper-info-brand'>{res.data[i].brand}</label>
                            <label className='topper-info-product'>{res.data[i].productName}</label>
                            <a className='topper-info-button' href={res.data[i].link}>Kom meer te weten</a>
                        </div>
                    </div>
                )
            }
            setData(tempData)         
        })
    }

    function onload() {
        setToken(sessionStorage.getItem('token'))

        initColors()

        setisLoaded(true)
    }

    async function isFavorite(product) {

        let tempData = await axios.post(url.concat('/isfavorite'), { token: sessionStorage.getItem('token'), product: product })

        return tempData.data
    }

    useEffect(() => {
        if (!isLoaded) { onload() }
    })

    useEffect(() => {
        authCall()
    }, [token])

    useEffect(() => {
        initColors()
    }, [colorRefresher])

    useEffect(() => {
        initColors()
    }, [data])

    return (
        <div className="App">
            <header className="App-header">

                <a className='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

            </header>
            <main>
                <div className='toppers'>
                    <h2>favorieten</h2>
                    <div className='toppers-container'>
                        <div><button class="scroll-arrow scroll-arrow-left" onClick="scrollLeft()">&#8249;</button></div>

                        <div class="scroll-container">
                            {data}
                        </div>
                    </div>
                </div>
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>

        </div>
    )
}

export default Favorites