import React from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react'


import './index.css';
import './App.css'
import './Bieren.css'

import { useNavigate, useParams } from 'react-router-dom'

function SterkeDranken() {

    const [isLoaded, setisLoaded] = useState(false)

    const [token, setToken] = useState('')

    const [productCards, setProductCards] = useState([])

    const navigate = useNavigate()

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    function onLoadCall() {

        axios.get(url.concat('/Sterk')).then((res) => {
            const products = res.data;
            const productCards = products.map(product => (
                <div className='card'>
                    <div className='topper-img-container'>
                        <div className='topper-img-blankspace'></div>
                        <div className='topper-img-container-2'>
                            <img className='topper-img' src={product.imgLink} alt={`image of ${product.productName}`} />
                        </div>
                        <button className='favorite-container' data-product={product.productName} aria-label='icon of a heart'>
                            <i className='fa-regular fa-heart'></i>
                        </button>
                    </div>
                    <div className='topper-info-container'>
                        <label className='topper-info-brand'>{product.brand}</label>
                        <label className='topper-info-product'>{product.productName}</label>
                        <a className='topper-info-button' href={product.link}>Kom meer te weten</a>
                    </div>
                </div>
            ));
            setProductCards(productCards);
        }).catch((error) => {
            console.log(error)
        })

    }


    function onload() {

            onLoadCall()

            setisLoaded(true)
    }

    function submitFilter(filters) {
        axios.post(url.concat('/Sterk/filter'), filters).then((res) => {
            const products = res.data;
            const productCards = products.map(product => (
                <div className='card'>
                    <div className='topper-img-container'>
                        <div className='topper-img-blankspace'></div>
                        <div className='topper-img-container-2'>
                            <img className='topper-img' src={product.imgLink} alt={`${product.productName}`} />
                        </div>
                        <button className='favorite-container' data-product={product.productName} aria-label='icon of a heart'>
                            <i className='fa-regular fa-heart'></i>
                        </button>
                    </div>
                    <div className='topper-info-container'>
                        <label className='topper-info-brand'>{product.brand}</label>
                        <label className='topper-info-product'>{product.productName}</label>
                        <a className='topper-info-button' href={product.link}>Kom meer te weten</a>
                    </div>
                </div>
            ));
            setProductCards(productCards);
        }).catch((error) => {
            console.log(error)
        })
    }


    useEffect(() => {
        if (!isLoaded) { onload() }
    }, [isLoaded])



    return (
        <div className="App">
            <header>
                <div className="App-header">
                    <a className='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                    <input type="text" placeholder="Search..."></input>

                    <button className="searchBut" aria-label='icon of a magnifying glass'><i class="fa fa-search"></i></button>

                    <div className="BoozeBook"><h1>BoozeBook</h1></div>

                </div>
                <nav className='navbar'>
                    <Link className='firstNavButton' to={'/bieren'}><h2>Bieren</h2></Link>
                    <Link to={'/sterke-dranken'}><h2>Sterke dranken</h2></Link>
                    <Link><h2>Café kompas</h2></Link>
                    <Link to={'/bartikeltjes'}><h2>(B)artikeltjes</h2></Link>
                    <Link to={'/developers-keuze'} className='lastNavButton'><h2>Developer's keuze</h2></Link>
                </nav>

            </header>
            <main>
            <section className='bier'>
                    <aside className="filter-section">

                        <ul className="filter-options">
                            <li className="filter-option">
                                <h3>Type</h3>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeRum" value="rum" />
                                    Rum
                                </label>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeWodka" value="wodka" />
                                    Wodka
                                </label>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeWhisky" value="whisky" />
                                    Whisky
                                </label>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeGin" value="gin" />
                                    Whisky
                                </label>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeKruid" value="kruidenlikeur" />
                                    Kruidenlikeur
                                </label>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeFruit" value="fruitlikeur" />
                                    Fruitlikeur
                                </label>
                                <label>
                                    <input className='typeFilter' type="checkbox" name="SterkeTypeOther" value="andereLikeur" />
                                    Andere
                                </label>
                            </li>
                            <li className="filter-option">
                                <h3>Alcohol Percentage</h3>
                                <label>
                                    <input className='alcoholFilter' type="checkbox" name="alcoholPercentageFree" value="Free" />
                                    Alcoholvrij
                                </label>
                                <label>
                                    <input className='alcoholFilter' type="checkbox" name="alcoholPercentageLow" value="Low" />
                                    0.5-15%
                                </label>
                                <label>
                                    <input className='alcoholFilter' type="checkbox" name="alcoholPercentageMedium" value="Med" />
                                    15.1-40%
                                </label>
                                <label>
                                    <input className='alcoholFilter' type="checkbox" name="alcoholPercentageHigh" value="High" />
                                    40.1%+
                                </label>
                                <label>
                                    <input className='alcoholCustomFilter1' type="number" name="alcoholPercentageCustom1" />
                                    -
                                    <input className='alcoholCustomFilter2' type="number" name="alcoholPercentageCustom2" />
                                    anders
                                </label>
                            </li>
                        </ul>
                        <button className="filter-submit-button" onClick={() => {
                            let selectedFilters = { typeFilters: [], alcoholFilters: [], customAlcoholFilters: [] };

                            document.querySelectorAll('.typeFilter').forEach((filter, i) => {
                                if (filter.checked) {
                                    selectedFilters.typeFilters.push(filter.value);
                                }
                            });

                            if (document.querySelector('.alcoholCustomFilter1').value !== '' && document.querySelector('.alcoholCustomFilter2').value !== '') {
                                selectedFilters.customAlcoholFilters.push(document.querySelector('.alcoholCustomFilter1').value);
                                selectedFilters.customAlcoholFilters.push(document.querySelector('.alcoholCustomFilter2').value);
                            } else {
                                document.querySelectorAll('.alcoholFilter').forEach((filter, i) => {
                                    console.log(filter);
                                    if (filter.checked) {
                                        selectedFilters.alcoholFilters.push(filter.value);
                                    }
                                });
                            }

                            submitFilter(selectedFilters);

                        }}>Bekijk Resultaten</button>
                    </aside>

                    <div className="product-cards">
                        {productCards}
                    </div>
                </section>
            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default SterkeDranken