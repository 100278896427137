import React from 'react'
import { Link } from 'react-router-dom';


import './index.css'
import './App.css'
import './Account.css'
import './Developer.css'

import { useNavigate } from 'react-router-dom'

function Developer() {

    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

    const navigate = useNavigate()

    return (

        <div className="App">
            <header className="App-header">

                <a className='header-logo-container' href='/'><img id="header-logo" src="/logo.png" alt="BoozeBook logo"></img></a>

                <div className="BoozeBook"><h1>BoozeBook</h1></div>

            </header>
            <main className='dev-mains'>

                <div className='header'>
                    <h2>Developers keuze</h2>
                    <p>Hieronder vindt je de voorkeur van elke developer op gebied van drank, café's, etc.</p>
                </div>

                <div className='developers'>  

                    <ul>

                    <li>
                        <div className='developer'>
                            <h3>Lenco De Moerlooze</h3>
                            <img src='https://i.imgur.com/u8vZQFb.png' alt='Pasfoto van Lenco De Moerlooze'></img>
                            <p>Hallo mijn Naam is Lenco De Moerlooze. Mijn voorkeur op vlak van drank ligt bij de bieren. Meer betreffende de doodgewone pils. Mijn favoriete pils merken zijn Jupiler
                            en Stella. Tegen een Maes of Primus zal ik ook wel geen nee zeggen. Mensen die Heineken of Cristal drinken en lekker vinden mogen voor mij
                            branden in de hel. Ik ben geen grote van fruitbieren maar een Lindemans Apppel gaat wel vlotjes binnen. 
                            Op vlak van sterke dranken drink ik graag wel een vodka redbull, aperol-spritz of bacardi cola. Ik drink ook wel graag
                            wisky maar dit neem ik niet veel aangezien dit vaak wel wat kost. Ik zit graag in alle cafe's, maar een echt bruin café krijgt wel nog mijn voorkeur.</p>
                        </div>
                    </li>

                    <li>
                        <div className='developer'>
                            <h3>Eytan Nurflus</h3>
                            <img src='https://i.imgur.com/mepvF9L.png' alt='foto van Eytan Nurflus'></img> 
                            <p>Ik ben Eytan Nurflus en ik ben een developer van BoozBook. Mijn keuze van drank is meer gericht naar sterke drank. Ik drink alleen maar sterke drank omdat ik geen bier, wijn , cava
                            en champagne lust. Af en toe lust ik 's avonds wel een rode of roze gin voor de tv maar dat doe ik niet zo vaak. Ik kan wel geen nee zeggen tegen een
                            "Cointreau pulco sprite", "rode vodka redbull" of "Cosmopolitan". Ik vind alle starke dranken lekker maar heb meer een voorkeur naar alcohol met een zoete smaak.
                            Mijn go to drink wannerr ik uit ga is een rode vodka redbull of een shot tequila.</p> 
                        </div>
                    </li> 

                    <li>
                        <div className='developer'>
                            <h3>Jannes De Cock</h3>
                            <img src='https://i.imgur.com/6e2xm8w.png' alt='Foto van Jannes De Cock in scoutsuniform'></img>
                            <p>Als een developer van BoozeBook kies ik Delirium als mijn absolute fovoriet. dit iconische Belgische bier is niet bekend voor zijn
                            roze olifant, maar ook om zijn unieke smaak en rijke karakter de combinatie van trdditie en innovatie maakt het een perfecte keuze
                            voor liefhebbers van speciaalbier.
                            </p>
                        </div>
                    </li>

                    <li>
                        <div className='developer'>
                            <h3>Kilian Kyndt</h3>
                            <img src='hn' alt=''></img>
                            <p></p>
                        </div>
                    </li>    
                </ul>
                </div>

            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Developer;