import axios from 'axios';
import { useEffect, useState, useCallback } from 'react'
import './App.css';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'

function App() {

  const [isLoaded, setisLoaded] = useState(false)

  const [token, setToken] = useState('')

  const [data, setData] = useState('')

  const [searchData, setSearchData] = useState({results: '', similar: ''})

  // const [colorRefresher, setColorRefresher] = useState(false)
  const [searchQuery, setSearchQuery] = useState('');

  const [topper1Data, setTopper1Data] = useState({ 'imgLink': '', 'brand': 'brand', 'productName': 'productName', 'link': '' })
  const [topper2Data, setTopper2Data] = useState({ 'imgLink': '', 'brand': 'brand', 'productName': 'productName', 'link': '' })
  const [topper3Data, setTopper3Data] = useState({ 'imgLink': '', 'brand': 'brand', 'productName': 'productName', 'link': '' })
  const [topper4Data, setTopper4Data] = useState({ 'imgLink': '', 'brand': 'brand', 'productName': 'productName', 'link': '' })

  const authCall = async () => {
    return await axios.post(url.concat('/auth'), { token: sessionStorage.getItem('token') }).then((res) => {
      setData(res.data)
    })
  }

  const topperCall = async (number) => {
    return await axios.get(url.concat('/topper')).then((res) => {
      setTopper1Data({ imgLink: res.data.n1.imgLink, brand: res.data.n1.brand, productName: res.data.n1.productName, link: res.data.n1.link })
      setTopper2Data({ imgLink: res.data.n2.imgLink, brand: res.data.n2.brand, productName: res.data.n2.productName, link: res.data.n2.link })
      setTopper3Data({ imgLink: res.data.n3.imgLink, brand: res.data.n3.brand, productName: res.data.n3.productName, link: res.data.n3.link })
      setTopper4Data({ imgLink: res.data.n4.imgLink, brand: res.data.n4.brand, productName: res.data.n4.productName, link: res.data.n4.link })
    })
  }

  const searchCall = async (query) => {
    return await axios.post(url.concat(`/search`), { query: query }).then((res) => {


      if (res.data.results === undefined) {
        return setSearchData({
          results: (
            <div className="direct-results">
              <div className="search-result-item">
                <p>Geen resultaten.</p>
              </div>
            </div>
          ),
          similar: (
            ''
          )
        });
      }

      if (res.data.results !== '' && res.data.similar !== '') {
        setSearchData({
          results: (
            <div className="direct-results">
              {res.data.results.map((result, index) => (
                <div className="search-result-item">
                  <img src={result.imgLink} alt={result.productName} />
                  <div className="search-result-info">
                    <label className="search-result-brand">{result.brand}</label>
                    <label className="search-result-product">{result.productName}</label>
                    <Link className="search-result-link overlay-link" to={result.link}></Link>
                  </div>
                </div>
              ))}
            </div>
          ),
          similar: (
            <div className="direct-results">
              <label>Vergelijkbaar: </label>
              {res.data.similar.map((result, index) => (
                <div className="search-result-item">
                  <img src={result.imgLink} alt={result.productName} />
                  <div className="search-result-info">
                    <label className="search-result-brand">{result.brand}</label>
                    <label className="search-result-product">{result.productName}</label>
                    <Link className="search-result-link overlay-link" to={result.link}></Link>
                  </div>
                </div>
              ))}
            </div>
          )
        });
      } else if (res.data.results !== '' && res.data.similar === '') {
        setSearchData({
          results: (
            <div className="direct-results">
              {res.data.results.map((result, index) => (
                <div className="search-result-item">
                  <img src={result.imgLink} alt={result.productName} />
                  <div className="search-result-info">
                    <label className="search-result-brand">{result.brand}</label>
                    <label className="search-result-product">{result.productName}</label>
                    <Link className="search-result-link overlay-link" to={result.link}></Link>
                  </div>
                </div>
              ))}
            </div>
          ),
          similar: (
            <div className="direct-results">
              <label>Vergelijkbaar: </label>
              <p>Geen vergelijkbare resultaten.</p>
            </div>
          )
        });
      } else {
        setSearchData({
          results: (
            <div className="direct-results">
                <p>Geen resultaten.</p>
            </div>
          ),
          similar: (
            <div className="direct-results">
              <label>Vergelijkbaar: </label>
              <p>Geen vergelijkbare resultaten.</p>
            </div>
          )
        });
      }
    });
  };

  function initColors() {
    const favoriteButtons = document.querySelectorAll('.favorite-container');
    // console.log(favoriteButtons)
    favoriteButtons.forEach(async (button) => {
      console.log(button.dataset.product)
      const isFav = await isFavorite(button.dataset.product);
      if (isFav !== 'Wrong token' && isFav !== 'No token provided') {
        button.style.color = isFav ? '#ff0077' : '#e7e7e7';
      } else {
        button.style.color = '#e7e7e7'
      }
      if (button.getAttribute('listener') === 'true') { return }
      button.addEventListener('click', async () => {
        const product = button.dataset.product;
        await favorite(product);
        const isFav = await isFavorite(product);
        if (isFav !== 'Wrong token' && isFav !== 'No token provided') {
          button.style.color = isFav ? '#ff0077' : '#e7e7e7';
        } else {
          button.style.color = '#e7e7e7'
        }
      });
      button.setAttribute('listener', 'true');
    });
  }

  async function onload() {
    setToken(sessionStorage.getItem('token'))

    await topperCall().then(() => {
      initColors()
    });

    setisLoaded(true)
  }



  useEffect(() => {
    if (!isLoaded) { onload() }
  }, [isLoaded])

  useEffect(() => {
    authCall()
  }, [token])

  const isFavorite = useCallback(async (product) => {
    // let tempData = await axios.post(url.concat('/isfavorite'), { token: sessionStorage.getItem('token'), product: product })

    // return tempData.data
  }, [])

  const favorite = useCallback(async (product) => {
    await axios.post(url.concat('/favorite'), { token: sessionStorage.getItem('token'), product: product })
  }, [])

  return (
    <div className="App">
      <header>
        <div className="App-header">
          <a className='header-logo-container' href='/'><img id="header-logo" src="./logo.png" alt="BoozeBook logo"></img></a>

          <div className="search-container">

          <input type="text" id="searchInput" placeholder="Search..." value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value); searchCall(searchQuery); if (e.target.value === '') {
            setSearchData({results: '', similar: ''})
          }}} autoComplete="off"></input>

          <button className="searchBut" aria-label='icon of a magnifying glass' onClick={async () => {
            searchCall(searchQuery);
          }}><i className="fa fa-search"></i></button>



          <div className="search-result-container">
            {searchData.results}
            <div className="similar-results">
            {searchData.similar}
            </div>
          </div>
        </div>

          <div className="BoozeBook"><h1>BoozeBook</h1></div>
          {Parser(data, {
            replace(domNode) {
              if (domNode.attribs && domNode.attribs.id === 'loginButton') {
                return <Link className='loginBut' to={'/login'}>login</Link>;
              } else if (domNode.attribs && domNode.attribs.id === 'accountButton') {
                return <Link className='loginBut' to={'/account'}>account</Link>;
              }
            }
          })}
        </div>
        <nav className='navbar'>
          <Link className='firstNavButton' to={'/bieren'}><h2>Bieren</h2></Link>
          <Link to={'/sterke-dranken'}><h2>Sterke dranken</h2></Link>
          <Link to={'/cafe-kompas'}><h2>Café kompas</h2></Link>
          <Link to={'/bartikeltjes'}><h2>(B)artikeltjes</h2></Link>
          <Link to={'/developers-keuze'} className='lastNavButton'><h2>Developer's keuze</h2></Link>
        </nav>
      </header>
      <main>
        <div className='toppers'>
          <h2>Toppers</h2>
          <div className='toppers-container'>
            <div><button class="scroll-arrow scroll-arrow-left" onClick="scrollLeft()">&#8249;</button></div>
            <div class="scroll-container">
              <div className='toppers-products toppers-1'>
                <div className='topper-img-container'>
                  <div className='topper-img-blankspace'></div>
                  <div className='topper-img-container-2'><img className='topper-img' src={topper1Data.imgLink} alt={'topper 1'} /></div>
                  <button className='favorite-container' data-product={`${topper1Data.productName}`} aria-label='icon of a heart'><i className='fa-regular fa-heart'></i></button>
                </div>
                <div className='topper-info-container'>
                  <label className='topper-info-brand'>{topper1Data.brand}</label>
                  <label className='topper-info-product'>{topper1Data.productName}</label>
                  <a className='topper-info-button' href={topper1Data.link}>Kom meer te weten</a>
                </div>

              </div>
              <div className='toppers-products toppers-2'>
                <div className='topper-img-container'>
                  <div className='topper-img-blankspace'></div>
                  <div className='topper-img-container-2'><img className='topper-img' src={topper2Data.imgLink} alt='topper 2' /></div>
                  <button className={`favorite-container`} data-product={`${topper2Data.productName}`} aria-label='icon of a heart'><i class="fa-regular fa-heart"></i></button>
                </div>
                <div className='topper-info-container'>
                  <label className='topper-info-brand'>{topper2Data.brand}</label>
                  <label className='topper-info-product'>{topper2Data.productName}</label>
                  <a className='topper-info-button' href={topper2Data.link}>Kom meer te weten</a>
                </div>

              </div>
              <div className='toppers-products toppers-3'>
                <div className='topper-img-container'>
                  <div className='topper-img-blankspace'></div>
                  <div className='topper-img-container-2'><img className='topper-img' src={topper3Data.imgLink} alt='topper 3' /></div>
                  <button className={`favorite-container`} data-product={`${topper3Data.productName}`} aria-label='icon of a heart'><i class="fa-regular fa-heart"></i></button>
                </div>
                <div className='topper-info-container'>
                  <label className='topper-info-brand'>{topper3Data.brand}</label>
                  <label className='topper-info-product'>{topper3Data.productName}</label>
                  <a className='topper-info-button' href={topper3Data.link}>Kom meer te weten</a>
                </div>
              </div>

              <div className='toppers-products toppers-4'>
                <div className='topper-img-container'>
                  <div className='topper-img-blankspace'></div>
                  <div className='topper-img-container-2'><img className='topper-img' src={topper4Data.imgLink} alt='topper 4' /></div>
                  <button className={`favorite-container`} data-product={`${topper4Data.productName}`} aria-label='icon of a heart'><i className="fa-regular fa-heart"></i></button>
                </div>
                <div className='topper-info-container'>
                  <label className='topper-info-brand'>{topper4Data.brand}</label>
                  <label className='topper-info-product'>{topper4Data.productName}</label>
                  <a className='topper-info-button' href={topper4Data.link}>Kom meer te weten</a>
                </div>
              </div>
              <div><button className="scroll-arrow scroll-arrow-right" onClick="scrollRight()">&#8250;</button></div>
            </div>
          </div>
        </div>
        {/* <div className='uitgelichtebartikels'>
          <h2>Uitgelichte (B)artikeltjes</h2>
          <p>hier komen een paar uitgelichte artikels</p>
        </div> */}
      </main>
      <footer>
        <p>Ons vakmanschap drink je met verstand.</p>
        <p>&copy; BoozeBook 2024</p>
      </footer>
    </div>
  );
}
export default App    