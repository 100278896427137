import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
import './App.css';
import './Account.css';
import './Bartikeltjes.css';

function Bartikeltjes() {
    const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com';
    const navigate = useNavigate();

    const articles = [
        { link:"kater-tip", title: 'Hoe Kom Je van een Kater Af? Tips om Snel te Herstellen.', src:'https://i.imgur.com/VkwX0Mw.png', alt:'Foto van een kater met bierflesjes', description: 'Een kater kan de volgende dag na een avondje drinken flink toeslaan. Hoofdpijn, misselijkheid, duizeligheid en een droge mond zijn veelvoorkomende symptomen. Gelukkig zijn er enkele manieren om sneller van een kater af te komen en je weer fris te voelen.' },
        { link:"bier-coctail", title: 'Bizarre Biercocktails: Meer Dan Alleen een Mazout', src: 'https://i.imgur.com/jD0lUXj.png', alt:'Foto van een Cornet-cocktail', description: 'Biercocktails zijn een unieke manier om je favoriete bieren naar een hoger niveau te tillen. De combinatie van bier met andere dranken kan verrassende en heerlijke resultaten opleveren. Hier zijn een paar biercocktails die je eens moet proberen.' },
        { link:"trappistenbier", title: 'De Geschiedenis van Trappistenbier: Uniek en Authentiek', src: 'https://i.imgur.com/PYJzRZm.png', alt:'Een foto van verschillende trappisten op een rij', description: 'Trappistenbier staat wereldwijd bekend om zijn hoge kwaliteit en rijke smaak. Maar wat maakt dit bier zo bijzonder, en waar komt het eigenlijk vandaan? ' },
        { link:"bier-met-eten", title: 'De Kunst van het Bier Pairen: Welke Gerechten Passen Perfect bij Jouw Favoriete Bier?', src: 'https://i.imgur.com/3olJg9b.png', alt:'Bier met een bierplank', description: 'Bier is niet alleen een geweldige drank voor bij sociale gelegenheden, het kan ook een geweldige aanvulling zijn op een maaltijd. Het juiste bier kan de smaken van een gerecht versterken en completeren. Hier zijn enkele tips om je favoriete bier te pairen met heerlijk eten.' },
        { link:"alcoholvrij-bier", title: 'Alcoholvrije Alternatieven: Genieten zonder Promille', src: 'https://i.imgur.com/PZEv6jf.png', alt:'Een foto van verschillende alcoholvrije bieren', description: 'De laatste jaren zien we een stijgende populariteit van alcoholvrije dranken. Of je nu een alcoholvrije dag inlast, moet rijden, of simpelweg wilt genieten zonder kater, er zijn steeds meer heerlijke alternatieven voor bier- en cocktailliefhebbers.' },
        { link:"bierproeverij", title: 'Bierproeverij Thuis Organiseren: Zo Doe Je Het!', src: 'https://i.imgur.com/HvKTQIY.png', alt:'Een foto van verschillende bieren, allemaal met een score', description: 'Een bierproeverij is een leuke manier om met vrienden nieuwe bieren te ontdekken en tegelijkertijd meer te leren over de smaken en stijlen die de bierwereld te bieden heeft. En het beste is: je kunt het gemakkelijk thuis organiseren! Hier zijn een paar tips om een geslaagde bierproeverij te organiseren.' },
    ];

    return (
        <div className="App">
            <header className="App-header">
                <a className="header-logo-container" href="/">
                    <img id="header-logo" src="/logo.png" alt="BoozeBook logo" />
                </a>
                <div className="BoozeBook">
                    <h1>BoozeBook</h1>
                </div>
            </header>

            <main>
                <div className='header'>
                    <h1>Bar Tikeltjes</h1>
                    <p>Deze week in de Bartikeltjes:</p>
                </div>

                <section className="bartikels-list">
                    {articles.map(article => (
                        <div className="bartikel">
                            <Link to={`/artikel/${article.link}`} className="bartikel-link">
                                <img className="artikel-foto" src={article.src} alt={article.alt} />
                                <h2>{article.title}</h2>
                                <p>{article.description}</p>
                            </Link>
                        </div>
                    ))}
                </section>
            </main>

            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}

export default Bartikeltjes;