import axios from 'axios';
import { useEffect, useState, useCallback } from 'react'
import './App.css';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import {
    APIProvider,
    ControlPosition,
    Map,
    MapControl
} from '@vis.gl/react-google-maps';


const url = '//boozebook-server1.eu-west-3.elasticbeanstalk.com'



function Cafe() {


    return (
        <div className="App">

            <header>
                <div className="App-header">
                    <a className='header-logo-container' href='/'><img id="header-logo" src="./logo.png" alt="BoozeBook logo"></img></a>
                    <div className="BoozeBook"><h1>BoozeBook</h1></div>
                </div>
                <nav className='navbar'>
                    <Link className='firstNavButton' to={'/bieren'}><h2>Bieren</h2></Link>
                    <Link to={'/sterke-dranken'}><h2>Sterke dranken</h2></Link>
                    <Link to={'/cafe-kompas'}><h2>Café kompas</h2></Link>
                    <Link to={'/bartikeltjes'}><h2>(B)artikeltjes</h2></Link>
                    <Link to={'/developers-keuze'} className='lastNavButton'><h2>Developer's keuze</h2></Link>
                </nav>
            </header>
            <main>

                <APIProvider apiKey={'AIzaSyDjxlCqJGa0gF_mtVCWdJ6DXG0VG5GyqTw'}>
                    <Map zoom={10} center={{ lat: 53.54992, lng: 10.00678 }} />
                </APIProvider>

            </main>
            <footer>
                <p>Ons vakmanschap drink je met verstand.</p>
                <p>&copy; BoozeBook 2024</p>
            </footer>
        </div>
    );
}
export default Cafe    